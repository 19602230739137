


















































import moment from 'moment';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import { handleNewsUrl } from '@/util/common';

export default Vue.extend({
  filters: {
    dateFormat(date: string) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm');
    },
  },
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;
    const newsRes: any = await ctx.$axios.post('/api/news/attentionNews', {
      pageNum,
      pageSize,
    });

    let news = {};
    if (newsRes.code === ResponseCode.SUCCESS) {
      news = newsRes.data;
    }

    return {
      news,
      pageNum,
      pageSize,
    };
  },
  data() {
    return {
      news: {},
      pageNum: 1,
      pageSize: 10,
    };
  },
  methods: {
    handleNewsUrl,
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const foloowRes: any = await this.$axios.post('/api/news/attentionNews', {
        pageNum,
        pageSize,
      });

      if (foloowRes.code === ResponseCode.SUCCESS) {
        this.news = foloowRes.data;
      }
    },
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
  },
  head() {
    return {
      title: '关注的资讯-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
