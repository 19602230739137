



































































import Vue from 'vue';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  async asyncData(ctx) {
    const lang = ctx.query.lang;
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const [myIndustryRes, industryRes]: any[] = await Promise.all([
      ctx.$axios.get('/api/user/myIndustry'),
      ctx.$axios.get(`/api/industry/getAll?locale=${langApi}`),
    ]);

    let myIndustry = [];
    let industry = [];
    let tagList: any = [];
    if (myIndustryRes.code === ResponseCode.SUCCESS) {
      myIndustry = myIndustryRes.data;
      myIndustryRes.data.forEach((item: any) => {
        tagList.push(item.industryId);
      });
    }

    if (industryRes.code === ResponseCode.SUCCESS) {
      let result = industryRes.data;
      // industryRes.data&&industryRes.data.forEach((item: any) => {
      //   item.children.forEach((item1: any) => {
      //     item1.check=false
      //   });
      // });
      result.map((firstItem: any) => {
        firstItem.children.map((secondItem: any) => {
          secondItem.check = false;
          if (tagList.includes(secondItem.id + '')) {
            secondItem.check = true;
          }
        });
      });
      industry = result;
    }

    return {
      myIndustry,
      industry,
    };
  },
  data(): any {
    return {
      myIndustry: [],
      industry: [],
      secondList: [],
      isLoading: false,
    };
  },
  created() {
    if (!_.isEmpty(this.myIndustry)) {
      this.myIndustry.forEach((item: any) => {
        this.secondList.push(+item.industryId);
      });
    }
  },
  methods: {
    // 获取关注的行业
    async handleGetMyIndustry() {
      const res: any = await this.$axios.get('/api/user/myIndustry');

      if (res.code === ResponseCode.SUCCESS) {
        let tagList: any = [];
        res.data.forEach((item: any) => {
          tagList.push(item.industryId);
        });
        this.industry.map((firstItem: any) => {
          firstItem.children.map((secondItem: any) => {
            secondItem.check = false;
            if (tagList.includes(secondItem.id + '')) {
              secondItem.check = true;
            }
          });
        });

        this.myIndustry = res.data;
      }
    },
    // 取消关注
    async handleDelIndustry(id: number) {
      const idx = this.secondList.indexOf(+id);
      if (idx > -1) {
        this.secondList.splice(idx, 1);
        const res: any = await this.$axios.post('/api/user/myIndustry/edit', {
          industryList: this.secondList,
        });
        if (res.code === ResponseCode.SUCCESS) {
          this.handleGetMyIndustry();
        }
      }
    },
    //选择行业
    getTag(data: any) {
      data.check = !data.check;
    },
    // 确认
    async handleSubmit() {
      this.secondList.splice(0);
      this.industry.forEach((item: any) => {
        item.children.forEach((item1: any) => {
          if (item1.check) {
            this.secondList.push(item1.id);
          }
        });
      });
      this.isLoading = true;
      const res: any = await this.$axios.post('/api/user/myIndustry/edit', {
        industryList: this.secondList,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.isLoading = false;
        this.secondList.splice(0);
        this.handleGetMyIndustry();
      }
    },
    // 返回
    handleCancel() {
      this.$router.push('/manager/');
    },
  },
  head() {
    return {
      title: '关注的行业-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
