

































































































































































































































































































































































































































































































































































import Vue from 'vue';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { adminHost } from '~/util/common';
import { uploadFile } from '~/util/oss';

export default Vue.extend({
  middleware: ['authenticated'],
  filters: {
    // 认证状态 0未认证 1-审核中 2已认证 3 已驳回
    // 认证状态 0未认证 1-审核中 2已认证 3 已驳回
    certificationStatus(status: number) {
      status = +status;
      switch (status) {
        case 0:
          return '未认证';
        case 1:
          return '审核中';
        case 2:
          return '已认证';
        case 3:
          return '已驳回';

        default:
          return '未认证';
      }
    },
  },
  async asyncData(ctx) {
    const url = _.get(ctx, 'route.path', '');
    const user = _.get(ctx, 'store.state.global.user', {});
    if (user.productNames && user.productNames.length > 0) {
      user.productNames = user.productNames.map((name: any) => {
        return { value: name };
      });
    } else {
      user.productNames = [{ value: '' }];
    }
    let result: any[];
    let credit = {};
    let myIndustry = {};
    let recent = {};

    if (/^\/manager\/?$/.test(url)) {
      result = await Promise.all([
        ctx.$axios.post('/api/user/credit/info', {
          pageNum: 1,
          pageSize: 15,
        }), // 积分记录
        ctx.$axios.get('/api/user/myIndustry'), // 关注的行业
        // ctx.$axios.get('/api/user/exhibition/recent'), // 用户近期关注展会列表
      ]);

      const [creditRes, myIndustryRes] = result;

      if (creditRes.code === ResponseCode.SUCCESS) {
        credit = creditRes.data;
      }

      if (myIndustryRes.code === ResponseCode.SUCCESS) {
        myIndustry = myIndustryRes.data;
      }

      // if (recentRes.code === ResponseCode.SUCCESS) {
      //   recent = recentRes.data;
      // }
    }

    return {
      credit,
      myIndustry,
      recent,
      isSign: _.get(ctx, 'store.state.global.user.isSigned', 0),
      user,
      nickName: user.nickName || user.userName,
      companyName: user.companyName || user.companyName,
      position: user.position || user.position,
      avatarUrl: user.avatar,
      companyOfficialWebsite:
        user.companyOfficialWebsite || user.companyOfficialWebsite,
      email: user.email,
      sex: user.sex,
      productNames: user.productNames || user.productNames,
    };
  },
  data(): any {
    return {
      // menu: ['我的关注', '资讯管理', '我的服务', '常用信息', '设置'],
      menu: ['我的关注', '我的订单', '常用信息', '设置'],
      myIndustry: [],
      current: 0,
      showInfo: true,
      nickName: '',
      user: {},
      avatarUrl: '',
      ossDir: '',
      credit: {},
      recent: {},
      isSign: 0,
      companyName: '',
      position: '',
      sex: '',
      companyOfficialWebsite: '',
      email: '',
      productNames: [{ value: '' }],
      userTotal: 0,
      fansTotal: 0,
      cooperateTypeList: [{ label: this.$t('personal.right.male') }, { label: this.$t('personal.right.female') }],
    };
  },
  watch: {
    $route(route) {
      // eslint-disable-line
      if (route.path === '/manager/') {
        this.$axios.get('/api/user/myIndustry').then((res: any) => {
          if (res.code === ResponseCode.SUCCESS) {
            this.myIndustry = res.data;
          }
        });
        this.$axios
          .post('/api/user/credit/info', {
            pageNum: 1,
            pageSize: 15,
          })
          .then((res: any) => {
            if (res.code === ResponseCode.SUCCESS) {
              this.credit = res.data;
            }
          }); // 关注的行业
        // this.$axios.get('/api/user/exhibition/recent').then((res: any) => {
        //   if (res.code === ResponseCode.SUCCESS) {
        //     this.recent = res.data;
        //   }
        // }); // 用户近期关注展会列表
      }
    },
  },
  created() {
    // this.nickName = _.get(this, '$store.state.global.user.nickName', '');
    this.getmyFollowList();
    this.getmyFansList();
  },
  methods: {
    // 获取关注列表
    async getmyFollowList() {
      const res: any = await this.$axios.post(
        `/api/userFollowApi/myFollowList`,
        {
          pageNum: 1,
          pageSize: 12,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.userTotal = res.data.total;
      }
    },
    async getmyFansList() {
      const res: any = await this.$axios.post(
        `/api/userFollowApi/myBeFollowedList`,
        {
          pageNum: 1,
          pageSize: 12,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.fansTotal = res.data.total;
      }
    },
    toFans() {
      this.$router.push('/manager/usersFans/');
    },
    toFollow() {
      this.$router.push('/manager/usersFollow/');
    },
    // 控制显示隐藏下拉菜单
    switchMenu(name: string) {
      if (this.menu.length === 0) {
        this.menu.push(name);
      } else {
        const index = this.menu.findIndex((item: any) => name === item);
        if (index > -1) {
          this.menu.splice(index, 1);
        } else {
          this.menu.push(name);
        }
      }
    },
    setClassName(name: string) {
      const index = this.menu.findIndex((item: any) => name === item);
      if (index > -1) {
        return 'user_link cont open';
      } else {
        return 'user_link cont';
      }
    },
    setClassNameIcon(name: string) {
      const index = this.menu.findIndex((item: any) => name === item);
      if (index > -1) {
        return 'el-icon-arrow-up';
      } else {
        return 'el-icon-arrow-down';
      }
    },
    moveLeft() {
      if (this.current !== 0) {
        this.$refs.content.style.transitionDuration = '700ms';
        this.$refs.content.style.transform = `translate3d(${
          410 * this.current + 410
        }px, 0px, 0px)`;
        const now = this.current + 1;
        this.current = now;
      }
    },
    moveRight() {
      if (this.recent.records.length > 2) {
        const recent = _.get(this, 'recent.records', []);
        if (this.current !== 2 - recent.length) {
          this.$refs.content.style.transitionDuration = '700ms';
          this.$refs.content.style.transform = `translate3d(${
            410 * this.current - 410
          }px, 0px, 0px)`;
          const now = this.current - 1;
          this.current = now;
        }
      }
    },
    resetInfo() {
      this.$router.push('/manager/update-info/');
    },
    // 上传前
    beforeAvatarUpload(file: any) {
      const isJPG = /image/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是图片格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传
    async handleUpload(option: any) {
      const file = option.file;
      const res: any = await uploadFile(file);
      if (!res) {
        this.$message.error('头像上传失败.');
      } else {
        this.avatarUrl = res;
        // this.handleEditUser('noback');
      }
    },
    validateNickname(value: string) {
      const phoneRegex = /1[3-9]\d{9}/;
      const landlineRegex =
        /((\+?\d{1,3}-?)?0\d{2,3}-\d{2,3})\d{2,3}(-\d{1,4}|$)/;
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
      const specialCharRegex = /[`!@#$%^&*()_+\-={};':"\\|,.<>/?~]/;

      return !(
        phoneRegex.test(value) ||
        landlineRegex.test(value) ||
        emailRegex.test(value) ||
        specialCharRegex.test(value)
      );
    },
    addDomain() {
      this.productNames.push({
        value: '',
      });
    },
    removeDomain(idx: number) {
      this.productNames.splice(idx, 1);
    },
    // 修改用户信息
    async handleEditUser(type: string = '') {
      let hasEmptyValue = false;
      const { avatarUrl, nickName, sex, companyName, position } = this;
      if (type === '') {
        if (!nickName) {
          this.$message.warning(this.$t('personal.right.modifyInfo_inputRule1'));
          return;
        }
        if (!sex) {
          this.$message.warning(this.$t('personal.right.modifyInfo_inputRule2'));
          return;
        }
        if (!this.validateNickname(nickName)) {
          this.$message.warning(this.$t('personal.right.modifyInfo_inputRule3'));
          return;
        }
        if (!companyName) {
          this.$message.warning(this.$t('personal.right.modifyInfo_inputRule4'));
          return;
        }
        this.productNames.forEach((item: any) => {
          if (item.value === '') {
            hasEmptyValue = true;
            this.$message.warning(this.$t('personal.right.modifyInfo_inputRule5'));
          }
        });
        if (hasEmptyValue) {
          return; // 中断后续的步骤
        }
        if (!position) {
          this.$message.warning(this.$t('personal.right.modifyInfo_inputRule6'));
          return;
        }
      }
      this.productNames = _.cloneDeep(
        this.productNames.map((item: any) => item.value)
      );
      const res: any = await this.$axios.post(
        '/api/followeApi/updateFollowUserInfo',
        {
          avatar:
            avatarUrl || _.get(this.$store, 'state.global.user.avatar', ''),
          nickName:
            nickName || _.get(this.$store, 'state.global.user.nickName', ''),
          companyName:
            companyName ||
            _.get(this.$store, 'state.global.user.companyName', ''),
          position:
            position || _.get(this.$store, 'state.global.user.position', ''),
          companyOfficialWebsite:
            this.companyOfficialWebsite ||
            _.get(this.$store, 'state.global.user.companyOfficialWebsite', ''),
          email:
            this.email || _.get(this.$store, 'state.global.user.email', ''),
          sex: sex || _.get(this.$store, 'state.global.user.sex', ''),
          productNames:
            this.productNames ||
            _.get(this.$store, 'state.global.user.productNames', ''),
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        await this.$store.dispatch('global/getUser');
        const user = _.get(this, '$store.state.global.user', {});
        this.user = user;
        if (this.productNames && this.productNames.length > 0) {
          this.productNames = this.productNames.map((name: any) => {
            return { value: name };
          });
        }
        // if (type === '') {
        //   this.resetInfo();
        // }
      }
    },
    async getCredit() {
      const res = await this.$axios.post('/api/user/credit/info', {
        pageNum: 1,
        pageSize: 15,
      }); // 积分记录
      if (res.code === ResponseCode.SUCCESS) {
        this.credit = res.data;
      }
    },
    // 用户签到
    async handleSign() {
      if (this.isSign !== 1) {
        const res = await this.$axios.get('/api/user/sign');
        if (res.code === ResponseCode.SUCCESS) {
          this.$store.dispatch('global/getUser');
          this.isSign = 1;
          this.getCredit();
        }
      } else {
        this.$message.error('用户已签到');
      }
    },
    // 去认证
    handleToAuth() {
      this.$router.push('/manager/auth/');
    },
    goAdmin() {
      const certificationStatus = _.get(
        this.$store,
        'state.global.user.certificationStatus',
        0
      );

      if (certificationStatus == 1) {
        const token = $cookies.get('access_token');
        window.open(`${adminHost}/?token=${token}`, '_blank');
      } else {
        this.$message.warning('请先进行企业认证.');
      }
    },
  },
  head() {
    return {
      title: '个人中心-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
