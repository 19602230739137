











































































import Vue from 'vue';
import _ from 'lodash';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;

    const fairRes: any = await ctx.$axios.post('/api/user/exhibition', {
      pageNum,
      pageSize,
    });
    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data;
    }

    return {
      pageNum,
      pageSize,
      fair,
    };
  },
  data() {
    return {
      checkAll: false,
      checkedCities: [],
      isIndeterminate: true,
      pageNum: 1,
      pageSize: 10,
      fair: {},
    };
  },
  methods: {
    handleCheckAllChange(checked: boolean) {
      if (checked) {
        const allChecked: any[] = [];
        const fairList = _.get(this, 'fair.records', []);

        fairList.forEach((item: any) => {
          allChecked.push(item.exhibitionId);
        });
        this.$data.checkedCities = allChecked;
      } else {
        this.$data.checkedCities = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value: any) {
      const checkedCount = value.length;
      const allLength = _.get(this, 'fair.records.length', 0);

      this.checkAll = checkedCount === allLength;
      this.isIndeterminate = checkedCount > 0 && checkedCount < allLength;
    },
    // 获取关注列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const res: any = await this.$axios.post('/api/user/exhibition', {
        pageNum,
        pageSize,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.$data.fair = res.data;
      }
    },
    // 取消关注
    async handleUnFollowFair(id: number) {
      // const res = await this.$axios.get(`/api/user/exhibition/${id}`);
      const res: any = await this.$axios.post(`/api/exhibition-follow/follow`, {
        exhibitionId: id,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.handleGetList();
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
  },
  head() {
    return {
      title: '关注的展会-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
