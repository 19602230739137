































































import Vue from 'vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  filters: {
    boothType(type: string) {
      // 0 光地展位 1 标准展位 2 进阶展位
      switch (type) {
        case '0':
          return this.$t('personal.right.raw_sace');
        case '1':
          return this.$t('personal.right.standard_booth');
        case '2':
          return this.$t('personal.right.advanced_booth');
        default:
          return '';
      }
    },
  },
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;

    const res: any = await ctx.$axios.post('/api/user/apply/list', {
      pageNum,
      pageSize,
    });

    let fair = {};
    if (res.code === ResponseCode.SUCCESS) {
      fair = res.data;
    }
    return {
      fair,
      pageNum,
      pageSize,
    };
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      fair: {},
    };
  },
  methods: {
    async handleGetList() {
      const res: any = await this.$axios.post('/api/user/apply/list', {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      });

      if (res.code === ResponseCode.SUCCESS) {
        this.fair = res.data;
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
  },
  head() {
    return {
      title: '我申请的展会-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
