























































import Vue from 'vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;

    const noticeRes: any = await ctx.$axios.post('/api/user/notice/list', {
      pageNum,
      pageSize,
    });
    let notice = {};
    if (noticeRes.code === ResponseCode.SUCCESS) {
      notice = noticeRes.data;
    }

    return {
      pageNum,
      pageSize,
      notice,
    };
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      notice: {},
    };
  },
  methods: {
    // 获取列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const res: any = await this.$axios.post('/api/user/notice/list', {
        pageNum,
        pageSize,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.notice = res.data;
      }
    },
    // 关注展会
    async handleFollow(id: number) {
      // const res: any = await this.$axios.get(`/api/user/exhibition/${id}`);
      const res: any = await this.$axios.post(`/api/exhibition-follow/follow`, {
        exhibitionId: id,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.handleGetList();
      }
    },
    // 取消提醒
    async handleRemind(id: number) {
      // const res: any = await this.$axios.get(`/api/user/notice/${id}`);
      const res: any = await this.$axios.post(`/api/exhibition-follow/follow`, {
        exhibitionId: id,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.handleGetList();
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
  },
  head() {
    return {
      title: '展会提醒-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
