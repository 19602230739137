
































import Vue from 'vue';
import HotelItem from './components/HotelItem.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  components: {
    HotelItem,
  },
  async asyncData(ctx) {
    const result: Record<string, any> = {};

    const payload = {
      pageNum: 1,
      pageSize: 10,
    };
    const res = (await ctx.$axios.post(
      '/api/hotelOrderApi/myHotelOrder',
      payload
    )) as any;
    if (res?.code === ResponseCode.SUCCESS) {
      result.myList = res.data.records;
      result.total = res.data.total;
    }

    return result;
  },
  data() {
    return {
      activeType: '0',
      pageNum: 1,
      pageSize: 10,
      myList: [],
      total: 0,
      EPayStatus: {
        0: '待支付',
        1: '已支付',
        2: '退款中',
        3: '已退款',
      } as any,
      // 1取消（创建未支付）， 0未支付，1已支付，2退款中，21发起退款,3已退款（用户退款，或者第三方创建失败）,10预定成功,11已入住
      ETabStatus: {
        '0': null,
        '1': 0,
        '2': 1,
        '3': 2,
        '4': 3,
      } as any,
      currentTicket: {},
      repaymentVisible: false,
    };
  },
  methods: {
    handleTabClick(tab: any) {
      this.activeType = tab.name;
      this.pageNum = 1;
      this.handleGetList();
    },
    // 获取订单列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const res: any = await this.$axios.post(
        '/api/hotelOrderApi/myHotelOrder',
        {
          status: this.ETabStatus[this.activeType],
          pageNum,
          pageSize,
        }
      );

      if (res?.code === ResponseCode.SUCCESS) {
        Object.assign(this, {
          myList: res.data.records,
          total: res.data.total,
        });
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
  },
  head() {
    return {
      title: '我的酒店-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
